import {  toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const Notify = ({ text, type }) => {
 const tt = text
 toast(text, {
   position: "top-left",
   autoClose: 5000,
   hideProgressBar: false,
   closeOnClick: true,
   pauseOnHover: true,
   draggable: true,
   progress: undefined,
   theme: "light",
   type: type,
 })
}
   
