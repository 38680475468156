import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"

export default function Pagination({
  itemsPerPage,
  items = [],
  setCurrentItems,
}) {
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)


  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % items.length

    setItemOffset(newOffset)
  }

  useEffect(() => {
    if (items.length > 0) {
       const endOffset = itemOffset + itemsPerPage
       setCurrentItems(items.slice(itemOffset, endOffset))
       setPageCount(Math.ceil(items.length / itemsPerPage))
    }
   
  }, [items, itemOffset, itemsPerPage])

  return (
    <ReactPaginate
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      renderOnZeroPageCount={null}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      disabledClassName="disableClass"
    />
  )
}
