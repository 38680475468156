import React from 'react'
import checkNesting from '../../checkNesting'

const Chidrens = ({
  as,
  styles,
  uiObjectRelationId,
  children,
  props,
  objectName,
  uiObjectId,
  className,

}) => {

  switch (as) {
    case "span":
      return (
        <span
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </span>
      )
    case "div":
      return (
        <div
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </div>
      )
    case "section":
      return (
        <section
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </section>
      )
    case "article":
      return (
        <article
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </article>
      )
    case "aside":
      return (
        <aside
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </aside>
      )
    case "p":
      return (
        <p
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </p>
      )
    case "ul":
      return (
        <ul
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </ul>
      )
    case "ol":
      return (
        <ol
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </ol>
      )
    case "li":
      return (
        <li
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </li>
      )
    case "h1":
      return (
        <h1
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h1>
      )
    case "h2":
      return (
        <h2
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h2>
      )
    case "h3":
      return (
        <h3
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h3>
      )
    case "h4":
      return (
        <h4
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h4>
      )
    case "h5":
      return (
        <h5
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h5>
      )
    case "h6":
      return (
        <h6
          className={className}
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        >
          {checkNesting(children, props.page)}
        </h6>
      )
    default:
      return (
        
        <div
          id={`primeagile__${uiObjectRelationId}__${uiObjectId}`}
          className={className}
          style={{ ...styles }}
          data-primeagile-object={objectName}
        > 
          
          {checkNesting(children, props.page)}
        </div>
      )
  }
}


export default Chidrens