import React, { useState } from "react"
import { useDispatch } from "react-redux"
//import "@fortawesome/fontawesome-free/js/all.js"
// import checkNesting from "../../checkNesting"
import { decrease, increase, removeItem } from "../../../../redux/slices/pages"
import styled from "styled-components"

const CartContainer = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 15px 0px;
  border-bottom: 1px solid #707070;

  h4 {
    font-size: 14px;
    color: #707070;
  }

  div {
    flex: 1;
  }

  .imageContainer {
    position: relative;
  }
`

const DeleteContainer = styled.span`
  color: #d2001a;
  cursor: pointer;
  position: absolute;
  font-size: 12px;
  background: #ccc;
  padding: 7px !important;
  border-radius: 18px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 6px;
  right: 35px;
  opacity: 0.7;
  transition: all 0.5s;

  :hover {
    opacity: 1;
    background-color: #fff;
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
`

const SubTotal = styled.span`
  float: right;
  color: #333;
  font-weight: 700;
`

const IncreaseDecrease = styled.span`
  border: 1px solid gray;
  border-radius: 20px;
  width: 90px !important;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0 10px !important;
  justify-content: space-around;
  margin-top: 10px;
  span {
    font-weight: 600;
    cursor: pointer;
  }

  .quantity {
    font-size: 16px;
    padding: 0;
  }
`

const CartItem = ({ img, title, price, quantity, key, name, shipping }) => {
  const dispatch = useDispatch()
  let subprice = parseFloat(price) ? parseFloat(price) * quantity : 0
  let subShipping = parseFloat(shipping) ? parseFloat(shipping) * quantity : 0

  const decreaseAmount = () => {
    if (quantity > 1) {
      dispatch(decrease(name))
    }
  }

  const increaseAmount = () => {
    dispatch(increase(name))
  }

  return (
    <CartContainer className="cart-item" key={key}>
      <div className="imageContainer">
        <img src={img} alt={title} width="80%" loading="lazy" />
        <DeleteContainer
          onClick={() => {
            dispatch(removeItem(name))
          }}
        >
          <i class="fas fa-trash"></i> 
        </DeleteContainer>
      </div>
      <div>
        <h4>{title}</h4>
        <h4 className="item-price">
          <span>
            {quantity} x {price}
          </span>
          <SubTotal>${subprice?.toFixed(2)}</SubTotal>
        </h4>
        <h4 className="shipping-price">
          <span>
            {/* {quantity} x {shipping} */}
            Shipping
          </span>
          <SubTotal>${subShipping?.toFixed(2)}</SubTotal>
        </h4>
        <IncreaseDecrease className="noselect">
          <span onClick={decreaseAmount}>-</span>
          <span className="quantity">{quantity}</span>
          <span onClick={increaseAmount}>+</span>
        </IncreaseDecrease>
      </div>
    </CartContainer>
  )
}
export default CartItem
