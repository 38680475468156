import React from "react"
import  Accordion  from "react-bootstrap/Accordion"
// import checkNesting from "../../checkNesting"

export default function Item(props) {
    const { jsonData } = props.object
    const { bodyText, headerText } = JSON.parse(jsonData)
    const {answer, question} = JSON.parse(jsonData)

    console.log("jsonData", jsonData)
  return (
    <React.Fragment>
      <Accordion.Item>
      <Accordion.Header>{headerText || question}</Accordion.Header>
      <Accordion.Body>
        {bodyText || answer}
        {/* {checkNesting(children)} */}
        </Accordion.Body>
    </Accordion.Item>
    </React.Fragment>
  )
}
